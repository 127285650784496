<template>
  <b-modal ref="modal" :hide-footer="true" title="데이터작업">
    <div class="row form-group">
      <div class="col-2">
        <label><trans> 대상테이블 </trans></label>
      </div>
      <div class="col-5">
        <select class="form-control" v-model="condition.timeType">
          <option v-for="(item, i) in timeTables" :key="i" :value="item.timeType"> {{ item.tbl }} </option>
        </select>
      </div>
      <div class="col-1 p-t-10">
        <i class="fa fa-arrow-right" />
      </div>
      <div class="col-4">
        <input type="text" class="form-control" v-model="targetTbl" disabled />
      </div>
    </div>
    <div class="row form-group">
      <div class="col-2">
        <label><trans>시작일</trans></label>
      </div>
      <div class="col-10">
        <div style="position: relative">
          <date-picker class="form-control" :value="condition.sDt" :config="dpSet" :placeholder="$t('시작일')" @input="condition.sDt = $event.parseDate()"></date-picker>
        </div>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-2">
        <label><trans>종료일</trans></label>
      </div>
      <div class="col-10">
        <div style="position: relative">
          <date-picker class="form-control" :value="condition.eDt" :config="dpSet" :placeholder="$t('종료일')" @input="condition.eDt = $event.parseDate()"></date-picker>
        </div>
      </div>
    </div>
    <div class="row form-group m-b-20">
      <div class="col-2">
        <label><trans>관제점들</trans></label>
      </div>
      <div class="col-10">
        <input type="text" class="form-control" v-model="condition.points" />
      </div>
    </div>

    <div v-show="condition.timeType === 7">
      <div class="row form-group m-t-20">
        <div class="col-2">
          <label><trans>샘플링</trans></label>
        </div>
        <div class="col-10">
          <input type="checkbox" class="m-r-10" v-model="samplingOptions.interpolation" />
          <label><trans> LAST보간 </trans></label>
        </div>
      </div>
      <div class="row form-group" v-if="condition.timeType == 7">
        <div class="col-2">&nbsp;</div>
        <div class="col-5">
          <input type="checkbox" class="m-r-10" v-model="samplingOptions.sampling" />
          <label><trans> 샘플링 </trans></label>
        </div>
        <div class="col-5" v-if="samplingOptions.sampling">
          <input type="checkbox" class="m-r-10" v-model="samplingOptions.isDebugWatch" />
          <label><trans> 계산결과기록 </trans></label>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-2">&nbsp;</div>
        <div class="col-10">
          <input type="checkbox" class="m-r-10" v-model="samplingOptions.summary" />
          <label><trans> 재취합 </trans></label>
        </div>
      </div>
    </div>

    <div v-show="condition.timeType !== 7">
      <div class="row form-group" v-if="condition.timeType !== 7">
        <div class="col-2">
          <label><trans>취합</trans></label>
        </div>
        <div class="col-10">
          <input type="checkbox" class="m-r-10" v-model="summaryOptions.clearLog" />
          <label><trans> 취합로그정리 </trans></label>
        </div>
      </div>
      <div class="row form-group" v-if="condition.timeType !== 1">
        <div class="col-2">&nbsp;</div>
        <div class="col-10">
          <input type="checkbox" class="m-r-10" v-model="summaryOptions.summary" />
          <label><trans> 재취합 </trans></label>
        </div>
      </div>
      <div class="row form-group" v-if="summaryOptions.clearLog || summaryOptions.summary">
        <div class="col-2">&nbsp;</div>
        <div class="col-10">
          <input type="checkbox" class="m-r-10" v-model="summaryOptions.isContinue" />
          <label><trans> 년취합까지 </trans></label>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-2">&nbsp;</div>
        <div class="col-10">
          <input type="checkbox" class="m-r-10" v-model="summaryOptions.updateStatus" />
          <label><trans> 통계갱신 </trans></label>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <div class="col-12">
        <button class="btn btn-default" @click="onClearCache()" v-b-tooltip.hover :title="$t('캐시삭제')"><i class="fa fa-play m-r-2"></i> {{ $t("캐시삭제") }}</button>
        <button class="btn btn-indigo pull-right" @click="onRun()" v-b-tooltip.hover :title="$t('실행')"><i class="fa fa-play m-r-2"></i> {{ $t("실행") }}</button>
      </div>
    </div>

  </b-modal>
</template>

<script>
import backEndApi from "@api/backEndApi.js";
import * as popupMessages from "@src/consts/popupMessageConsts"

const defaultCondition = {
  timeType: 7,
  targetType: 6,
  sDt: new Date().format("yyyy-MM-dd 00:00").parseDate(),
  eDt: new Date().format("yyyy-MM-dd 23:59").parseDate(),
  points: "",
};

const defaultSamplingOptions = {
  interpolation: true,
  sampling: true,
  isDebugWatch: false,
  summary: true,
};

const defaultSummaryOptions = {
  clearLog: true,
  summary: true,
  isContinue: true,
  updateStatus: false,
};

export default {
  name: "SamplingPopup",
  props: ["options"],
  data() {
    return {
      dpSet: {
        format: "YYYY-MM-DD HH:mm",
        useCurrent: false,
        showClear: true,
        showClose: true,
        minDate: new Date(2018, 1, 1),
        maxDate: false,
      },

      timeTables: global.xe.PtLib.TimeTables,

      condition: {
        ...defaultCondition,
        ...this.options,
      },

      samplingOptions: {
        ...defaultSamplingOptions,
      },
      summaryOptions: {
        ...defaultSummaryOptions,
      },

      apiResult: "",
    };
  },
  computed: {
    targetTbl() {
      let tt = this.timeTables.find((v) => v.timeType == this.condition.timeType - 1);
      return tt ? tt.tbl : "";
    },
  },
  mounted() {
    this.initSetting();
  },
  methods: {
    initSetting() {
      this.condition = {
        ...defaultCondition,
        ...this.options,
      };
    },
    show() {
      this.initSetting();
      this.$refs.modal.show();
    },
    hide() {
      this.$refs.modal.hide();
    },
    onRun() {
      if (this.condition.timeType === 7) {
        this.runSampling();
      } else {
        this.runSummary();
      }
    },
    runSampling() {
      let detail = [];
      detail.push(this.samplingOptions.interpolation ? "보간" : "");
      detail.push(this.samplingOptions.sampling ? `샘플링${this.samplingOptions.isDebugWatch ? "(계산결과기록)" : ""}` : "");
      detail.push(this.samplingOptions.summary ? "재취합" : "");
      detail = detail.filter((v) => v);

      let { sDt, eDt, points } = this.condition;

      this.alertQuestion(popupMessages.POINT_DATA_SEARCH_SAMPLING_POPUP_MESSAGE).then((result) => {
        if (!result.value) return;

        backEndApi.live.reSampling(sDt.format("yyyyMMddHHmmss"), eDt.format("yyyyMMddHHmmss"), points, this.samplingOptions).then(({ data }) => {
          if (this.$err(data)) return;

          this.alertNoti(popupMessages.POINT_DATA_SEARCH_COMMON_POPUP_MESSAGE);
        });
      });
    },
    runSummary() {
      let detail = [];
      detail.push(this.summaryOptions.clearLog ? "취합로그정리" : "");
      detail.push(this.summaryOptions.summary ? `재취합${this.summaryOptions.isContinue ? "(년취합까지)" : ""}` : "");
      detail.push(this.summaryOptions.updateStatus ? "통계갱신" : "");
      detail = detail.filter((v) => v);

      let { timeType, sDt, eDt, points } = this.condition;

      this.alertQuestion(popupMessages.POINT_DATA_SEARCH_COLLECTION_POPUP_MESSAGE).then((result) => {
        if (!result.value) return;

        // 화면은 소스테이블의 timeType인데, 함수는 타겟테이블의 timeType으로 되어 있음. 그래서 -1 했음.
        backEndApi.live.runSummary(timeType - 1, sDt.format("yyyyMMddHHmmss"), eDt.format("yyyyMMddHHmmss"), points, this.summaryOptions).then(({ data }) => {
          if (this.$err(data)) return;

          this.alertNoti(popupMessages.POINT_DATA_SEARCH_COMMON_POPUP_MESSAGE);
        });
      });
    },
    onClearCache() {
      this.alertConfirmWarning(popupMessages.POINT_DATA_SEARCH_CASH_DELETE_POPUP_MESSAGE).then((result) => {
        if (!result.value) return;

        backEndApi.engineering.clearCache().then(({ data }) => {
          if (this.$err(data)) return;
        });
      });
    },
  },
};
</script>
